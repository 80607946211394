export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'outlet',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'code',
    label: 'field.code',
    rules: 'required|max:100',
    type: 'text',
    disabled: true,
  },
  {
    hide: true,
    skip: true,
    cols: 12,
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'messageKm',
    label: 'field.messageKm',
    rules: 'max:200',
    type: 'text',
  },
  {
    key: 'messageEn',
    label: 'field.messageEn',
    rules: 'max:200',
    type: 'text',
  },
  {
    hide: true,
    skip: true,
    cols: 12,
  },
  // {
  //   key: 'isUnlimited',
  //   label: 'field.unlimited',
  //   rules: '',
  //   type: 'checkbox',
  //   cols: 'auto',
  // },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
